<template>
  <div class="login">
    <div class="main">
      <img
        class="pp"
        src="https://huabanshoutiktok.oss-cn-hangzhou.aliyuncs.com/mini/images/1689573826867633.png"
        alt=""
      />
      <div class="right">
        <div class="logo">
          <img class="logoImg" src="../../static/img/logo.png" alt="" />
          <h2 class="title1">二当家</h2>
        </div>
        <div class="bottom" v-show="isShow == 0">
          <!-- tab切换 -->
          <div class="headTitle">
            <div
              :class="active == index ? 'activeLogins' : 'logins'"
              v-for="(item, index) in potoTel"
              :key="index"
              @click="tabChange(index)"
            >
              {{ item.tilte }}
            </div>
          </div>
          <!-- 手机号登录 -->
          <div class="teleForm" v-show="active == 0">
            <el-form ref="teleForm" :rules="rules1" :model="teleForm">
              <el-form-item prop="contact_phone">
                <el-input
                  placeholder="请输入微信绑定的手机号"
                  v-model="teleForm.contact_phone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <div class="code hugu">
                  <el-input
                    placeholder="请输入验证码"
                    v-model="teleForm.code"
                  ></el-input>
                  <div
                    :class="{
                      getCode: true,
                      redCode: isCounting,
                    }"
                    @click="getCode"
                  >
                    {{ buttonTxt }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button @click="codeLogin('teleForm')">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- 账户密码登录 -->
          <div class="teleForm1" v-show="active == 1">
            <el-form
              :label-position="labelPosition"
              :model="accountForm"
              :rules="rules"
              ref="accountForm"
            >
              <el-form-item label="账户" prop="contact_phone">
                <el-input
                  autocomplete="off"
                  placeholder="请输入登录账户"
                  v-model="accountForm.contact_phone"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  autocomplete="off"
                  placeholder="请输入登录密码"
                  v-model="accountForm.password"
                  type="password"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="forget">
                  <el-checkbox label="记住密码" v-model="rember"></el-checkbox>
                  <span @click="toVerify">忘记密码？</span>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="margin-top: 20px"
                  @click="accountLogin('accountForm')"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- 按钮 -->
        </div>
        <!--忘记密码手机号验证 -->
        <div class="forgetPassword">
          <div class="verify">
            {{ isShow == 1 ? "手机号验证" : isShow == 2 ? "修改密码" : "" }}
          </div>
          <div class="teleForm">
            <el-form ref="forgetForm" :rules="rules2" :model="forgetForm">
              <div v-show="isShow == 1">
                <el-form-item prop="phone">
                  <el-input
                    placeholder="请输入微信绑定的手机号"
                    v-model="forgetForm.phone"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="code hugu">
                    <el-input
                      placeholder="请输入验证码"
                      v-model="forgetForm.code"
                    ></el-input>
                    <div
                      :class="{
                        getCode: true,
                        redCode: isCounting,
                      }"
                      @click="getCode"
                    >
                      {{ buttonTxt }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="btns">
                    <div class="backBTn" @click="toBack">返回</div>
                    <div class="stepBTn" @click="toStep1">下一步</div>
                  </div>
                </el-form-item>
              </div>
              <!-- 确认新密码 -->
              <div v-show="isShow == 2">
                <el-form-item prop="password">
                  <el-input
                    autocomplete="off"
                    placeholder="请输入新密码"
                    v-model="forgetForm.password"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="confirm_password">
                  <el-input
                    autocomplete="off"
                    placeholder="请输入新密码"
                    v-model="forgetForm.confirm_password"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="modify">
                    <div class="oneBtn" @click="toBack">返回</div>
                    <div class="sureBtn" @click="sureMoifty('forgetForm')">
                      确定
                    </div>
                  </div>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="fllings">
      <div>
        <span>©</span>
        <span>2024</span>
        <span>四川银弓科技</span>
      </div>
      <div>
        <img
          class="guoImg"
          src="https://huabanshoutiktok.oss-cn-hangzhou.aliyuncs.com/mini/images/1707295725934842.png"
          alt=""
        />
        <img
          class="guoImg"
          src="https://huabanshoutiktok.oss-cn-hangzhou.aliyuncs.com/mini/images/1707295791114957.png"
          alt=""
        />
      </div>
      <div class="icp-i" @click="toICp">
        <span>备案号：</span>
        <span>蜀ICP备2022006999号-2</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    api_login,
    api_sendCode,
    api_codeLogin,
    api_forgotPassword,
  } from "@/api/login";
  import { checkAccount, checkYzm, checkPwd } from "../../utils/validator";
  // 如果使用上面方式报错的话可以在用import引入进来
  import { Base64 } from "js-base64";

  export default {
    data() {
      var validatePass111 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else if (
          //  !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%&*]{8,16}$/.test(value)
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{8,16}$/.test(
            value
          )
        ) {
          callback(
            new Error("长度为8-16位密码，包含字母(大小写)+数字，支持特殊字符")
          );
        } else {
          if (this.forgetForm.confirm_password !== "") {
            this.$refs.forgetForm.validateField("confirm_password");
          }
          callback();
        }
      };
      var validatePass222 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.forgetForm.password) {
          callback(new Error("新密码和确认新密码不一致!"));
        } else {
          callback();
        }
      };
      return {
        potoTel: [
          {
            tilte: "手机号登录",
          },
          {
            tilte: "账户密码登录",
          },
        ],
        rember: false,
        isCounting: false,
        fristTime: true,
        countdown: 0, //计时器
        timer: null,
        active: 0,
        isShow: 0,
        labelPosition: "top",
        accountForm: {
          contact_phone: "",
          password: "",
        },
        teleForm: {
          contact_phone: "",
          code: "",
        },
        forgetForm: {
          password: "",
          confirm_password: "",
          code: "",
          phone: "",
        },
        rules: {
          contact_phone: [{ validator: checkAccount, trigger: "blur" }],
          password: [{ validator: checkPwd, trigger: "blur" }],
        },
        rules1: {
          contact_phone: [{ validator: checkAccount, trigger: "blur" }],
          code: [{ validator: checkYzm, trigger: "blur" }],
        },
        rules2: {
          phone: [
            {
              validator: checkAccount,
              trigger: "blur",
            },
          ],
          code: [{ validator: checkYzm, trigger: "blur" }],
          password: [
            {
              validator: validatePass111,
              trigger: "blur",
              required: true,
            },
          ],
          confirm_password: [
            {
              validator: validatePass222,
              trigger: "blur",
              required: true,
            },
          ],
        },
        defaultRember: false,
      };
    },
    created() {},
    mounted() {
      const tt = Base64.decode(localStorage.getItem("tt") || "");
      const ty = tt.split("~");
      const r = ty[2] == "true" ? true : false;
      if (r) {
        this.accountForm.contact_phone = ty[0];
        this.accountForm.password = ty[1];
        this.rember = r;
        this.defaultRember = r;
      }
    },
    computed: {
      buttonTxt() {
        return this.fristTime
          ? "获取验证码"
          : this.isCounting
          ? `${this.countdown}s重新获取`
          : "重新获取";
      },
    },
    methods: {
      toICp() {
        window.open("https://beian.miit.gov.cn", "_blank");
      },
      // 切换tab
      tabChange(index) {
        this.active = index;
        if (index) {
          this.$refs.teleForm.resetFields();
          clearInterval(this.timer);
          this.isCounting = false;
        } else {
          if (!this.defaultRember) {
            this.$refs.accountForm.resetFields();
            this.rember = false;
          }
        }
      },
      // 账号登录
      accountLogin(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const loginReq = {
              password: this.accountForm.password,
              phone: this.accountForm.contact_phone,
              commonParams: {
                latitude: String(-1),
                longitude: String(-1),
                areaCode: Number(0),
                pid: Number(0),
                jsCode: "",
                appid: "wx9dc4d706c67522d2",
              },
            };
            api_login(loginReq).then((res) => {
              if (res.data.code == 200) {
                if (this.rember) {
                  const saveStr = `${this.accountForm.contact_phone}~${this.accountForm.password}~${this.rember}`;
                  const tt = Base64.encode(saveStr);
                  localStorage.setItem("tt", tt);
                } else {
                  localStorage.removeItem("tt");
                }
                localStorage.setItem("twoToken", JSON.stringify(res.data.data));
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push("/home");
                }, 1500);
              }
            });
          } else {
            return false;
          }
        });
      },
      // 获取验证码
      getCode() {
        if (this.teleForm.contact_phone || this.forgetForm.phone) {
          if (this.isCounting) {
            return;
          }
          this.fristTime = false;
          this.isCounting = true;
          this.countdown = 60; //赋值60秒
          this.timer = setInterval(() => {
            this.countdown--; //递减
            if (this.countdown === 0) {
              // === 0 变成获取按钮
              clearInterval(this.timer);
              this.isCounting = false;
            }
          }, 1000);
          api_sendCode({
            phone: this.teleForm.contact_phone
              ? this.teleForm.contact_phone
              : this.forgetForm.phone,
            scene: this.isShow == 0 ? "LOGIN" : "RESET_PWD",
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "发送成功",
                  type: "success",
                });
              }
            })
            .catch((err) => {
              clearInterval(this.timer);
              this.isCounting = false;
            });
        }
      },
      // 验证码登录
      codeLogin(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const loginReq = {
              smsCode: String(this.teleForm.code),
              phone: this.teleForm.contact_phone,
              commonParams: {
                latitude: String(-1),
                longitude: String(-1),
                areaCode: Number(0),
                pid: Number(0),
                jsCode: "",
                appid: "wx9dc4d706c67522d2",
              },
            };
            api_codeLogin(loginReq).then((res) => {
              if (res.data.code == 200) {
                localStorage.setItem("twoToken", JSON.stringify(res.data.data));
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push("/home");
                }, 1500);
              }
            });
          } else {
            return false;
          }
        });
      },
      // 忘记密码
      toVerify() {
        this.isShow = 1;
      },
      // 返回上一步
      toBack() {
        this.isShow -= 1;
      },
      // 下一步
      toStep1() {
        if (this.isShow === 1) {
          let phonePass = false;
          this.$refs["forgetForm"].validateField("phone", (err) => {
            if (!err) {
              phonePass = true;
            }
          });
          let codePass = false;
          this.$refs["forgetForm"].validateField("code", (err) => {
            if (!err) {
              codePass = true;
            }
          });
          if (codePass && phonePass) {
            this.isShow += 1;
          }
        }
      },
      // 确定登录
      sureMoifty(forgetForm) {
        this.$refs[forgetForm].validate((valid) => {
          if (valid) {
            api_forgotPassword({
              confirmpassword: this.forgetForm.confirm_password,
              password: this.forgetForm.password,
              code: this.forgetForm.code,
              phone: this.forgetForm.phone,
              scene: "RESET_PWD",
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                localStorage.clear();
                this.active == 1;
                this.isShow = 0;
                this.rember = false;
                this.accountForm.contact_phone = "";
                this.accountForm.password = "";
              }
            });
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  /deep/.el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }
  .login {
    background-image: url("http://huabanshoutiktok.oss-cn-hangzhou.aliyuncs.com/mini/images/1687336305983760.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .forget {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666;
    }
    .fllings {
      font-size: 17px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // text-align: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto;
      color: #fff;
      .guoImg {
        width: 26px;
        height: 26px;
        margin-left: 8px;
      }
      .icp-i {
        margin-left: 4px;
      }
      .icp-i:hover {
        cursor: pointer;
        text-decoration: underline;
        border-bottom: 1px solid #8a38f5;
      }
    }

    .main {
      width: 1096px;
      height: 666px;
      background: #ffffff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 50px 74px 56px 104px;

      .pp {
        width: 509px;
        height: 572px;
      }

      .right {
        width: 444px;
        height: 453px;
        padding-top: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;

        .logo {
          display: flex;
          align-items: center;
          justify-content: center;

          .logoImg {
            width: 100px;
            height: 100px;
          }

          .title1 {
            font-size: 36px;
            font-weight: bold;
            color: #ff616d;
            margin-left: 19px;
          }
        }

        .bottom {
          width: 100%;

          .headTitle {
            display: flex;
            justify-content: space-around;
            align-content: center;
            padding: 0 50px;
            margin-top: 30px;

            .logins {
              color: #bbbbbb;
              font-size: 18px;
              height: 50px;
              line-height: 50px;
              width: 108px;
              text-align: center;
            }

            .activeLogins {
              font-size: 18px;
              height: 50px;
              line-height: 50px;
              color: #ff656d;
              position: relative;
              width: 108px;
              text-align: center;

              &::after {
                display: inline-block;
                content: "";
                width: 41px;
                height: 0px;
                opacity: 1;
                border: 2px solid #ff656d;
                position: absolute;
                bottom: 0;
                right: 25px;
              }
            }
          }
        }

        .teleForm {
          text-align: center;
          margin-top: 30px;

          .modify {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 50px;
            margin-top: 60px;

            .oneBtn {
              width: 40%;
              height: 40px;
              line-height: 40px;
              border: 1px #999 solid;
              border-radius: 4px;
            }

            .sureBtn {
              width: 55%;
              height: 40px;
              line-height: 40px;
              background: linear-gradient(38deg, #ff616d 0%, #ff806d 100%);
              border-radius: 4px;
              color: #ffffff;
              text-align: center;
            }
          }

          /deep/.el-form-item__error {
            left: 55px;
          }

          .el-input {
            width: 340px;
            height: 50px;

            border-radius: 6px;

            /deep/.el-input__inner {
              border: none;
              background: #f8f8f8;
              height: 50px;
            }
          }

          .code {
            display: flex;
            align-items: center;
            justify-content: center;

            .el-input {
              width: 222px;
              height: 50px;

              border-radius: 6px;

              /deep/.el-input__inner {
                border: none;
                background: #f8f8f8;
                height: 50px;
              }
            }

            .getCode {
              margin-left: 11px;
              width: 107px;
              height: 42px;
              border-radius: 6px;
              opacity: 1;
              border: 1px solid #d6d6d6;
              color: #d6d6d6;
            }

            .redCode {
              color: red;
            }
          }
        }

        .teleForm1 {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .el-form-item {
            margin-bottom: 10px;
            font-weight: bold;

            .el-input {
              width: 340px;
              height: 50px;
              border-radius: 6px;

              /deep/.el-input__inner {
                border: none;
                background: #f8f8f8;
                height: 50px;
              }
            }
          }
        }

        .el-button {
          width: 340px;
          height: 44px;
          background: linear-gradient(38deg, #ff616d 0%, #ff806d 100%);
          border-radius: 25px;
          color: #ffffff;
          border: none;
          font-size: 18px;
        }

        .forgetPassword {
          width: 100%;
          margin-top: 30px;

          .verify {
            text-align: center;
          }

          .btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 50px;
            margin-top: 60px;

            .backBTn {
              width: 40%;
              height: 40px;
              line-height: 40px;
              border: 1px #999 solid;
              border-radius: 4px;
            }

            .stepBTn {
              width: 55%;
              height: 40px;
              line-height: 40px;
              background: linear-gradient(38deg, #ff616d 0%, #ff806d 100%);
              border-radius: 4px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
</style>
