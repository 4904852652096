import goHttp from './goHttp'
// 账户密码登录
const moduleUrl = 'user-center'
export const api_login = (data, headers) => {
    return goHttp({
        url: '/user/login/account',
        method: 'post',
        data,
        headers: {
            ...headers,
            moduleUrl
        }
    })
}
// 发短信验证码
export const api_sendCode = (data, headers) => {
    return goHttp({
        url: '/sms/send',
        method: 'post',
        data,
        headers: {
            ...headers,
            moduleUrl
        }
    })
}
// 验证码登录
export const api_codeLogin = (data, headers) => {
    return goHttp({
        url: '/user/login/aliSmsLogin',
        method: 'post',
        data,
        headers: {
            ...headers,
            moduleUrl
        }
    })
}
// 设置密码
export const api_resetPassword = (data, headers) => {
    return goHttp({
        url: '/user/setPassword',
        method: 'post',
        data,
        headers: {
            ...headers,
            moduleUrl
        }
    })
}
// 忘记密码
export const api_forgotPassword = (data, headers) => {
    return goHttp({
        url: '/user/retrievePassword',
        method: 'post',
        data,
        headers: {
            ...headers,
            moduleUrl
        }
    })
}