// 引入axios
import axios from 'axios'
// 引入elementui的Message
import { Message } from 'element-ui'
import router from '@/router'
import { getHeaders } from './headerRequest'
// 配置服务器地址
// axios.defaults.baseURL = process.env.VUE_APP_API_URL_GO
// axios.defaults.baseURL = '/api-dev'
const service = axios.create({
    // baseURL: process.env.VUE_APP_API_URL_GO ,
    baseURL: '',
    timeout: 200000 // 请求超时时间
})
// process.env.VUE_APP_API_URL_GO_BEFORE + api + process.env.VUE_APP_API_URL_GO_AFTER
service.interceptors.request.use(config => {

    console.log(config, 444444444)

    config.headers = { ...config.headers, ...getHeaders() }
    const baseURL = process.env.VUE_APP_API_URL_GO_BEFORE + config.headers.moduleUrl + process.env.VUE_APP_API_URL_GO_AFTER
    config.url = baseURL + config.url
    delete config.headers.moduleUrl
    return config
}, err => {
    return Promise.reject(err)
})
// 响应拦截器
service.interceptors.response.use(res => {
    if (res.data.code == 202) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 422) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 403) {
        Message.error(res.data.msg)
    }
    if (res.data.code == 401) {
        localStorage.removeItem('tk')
        Message.error(res.data.msg);
        router.push('/')
    }
    if (res.data.code == 444) {
        Message.error(res.data.msg);
    }
    if (res.data.code == 500) {
        Message.error(res.data.msg);
    }
    return res
}, err => {
    // console.log(123, err);
    if (err.code == 'ECONNABORTED') {
        Message.error('请求超时')
    }
    if (err.code == 'ERR_NETWORK') {
        Message.error(err.Message)
    }
    return Promise.reject(err)
})

export default service