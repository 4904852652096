// import { refresh_Token } from '@/api/login.ts'
// export async function refreshToken(params) {
//     // 先哦判断是否快过期 返回的失效时间是秒
//     return
//     let { data } = await refresh_Token(params)
//     if (data.code === 200) {
//         localStorage.setItem('video-ai-t', data.data)
//     }
// }
export const getHeaders = () => {
    // 请求头配置
    let headers = {},
        token = localStorage.getItem('twoToken');
    // Bearer ${token}
    if (token) {

        // 判断token是否过期
        let jwt = JSON.parse(token);
        headers['authorization'] = jwt.token
        headers['refreshToken'] = jwt.refreshToken
    }
    // headers['application'] = 'idword'
    headers['application'] = 'jiandaoshou'
    headers['source'] = 'PC'
    headers["business"] = 'merchant'

    return headers
}